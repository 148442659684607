<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/banner_img5.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/business?t=${index}`">{{item.name}}</a>
                <span></span>
              </div>
              <!-- <div class="listSlidListInner">
                <a href="/list?t=1">项目动态</a>
                <span></span>
              </div> -->
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl listHdName">{{hdName}}</div>
              <div class="fr listHdGrop"><a :href="`${apiName}`">首页</a> · <a :href="`${apiName}/business`">{{slideHd.title}}</a> · <span class="txt">{{hdName}}</span> </div>
            </div>
            <div class="listContainer" v-html="pageCont">
              
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'list',
  data(){
    return {
      navId: 3,
      hdTitle: '',
      hdName: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '核心业务',
        sub: 'CORE BUSINESS',
      },
      slideList:[
        // {
        //   title: '智能安防',
        //   id: '11111'
        // },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 20,
      listData: [],
      page: [
        '<p>智慧交通</p><p>智慧交通</p><p>智慧交通</p>',
        '<p>智慧旅游</p><p>智慧旅游</p><p>智慧旅游</p> <p>智慧旅游</p><p>智慧旅游</p>',
        '<p>智能网联</p><p>智能网联</p><p>智能网联</p> <p>智能网联</p><p>智能网联</p>',
        '<p>智慧金融</p><p>智慧金融</p><p>智慧金融</p> <p>智慧金融</p><p>智慧金融</p>',
        '<p>智慧城市</p><p>智慧城市</p><p>智慧城市</p> <p>智慧城市</p>',
        '<p>智慧物联</p><p>智慧物联</p><p>智慧物联</p>',
      ]
    }
  },
  mounted(){
    var _this = this
    _this.getSubNav()
    if(_this.$route.query.t){
      _this.active = _this.$route.query.t
      // _this.navNameShow = true
      // _this.name1 = _this.$route.query.navName;
      // _this.navNameUrl = _this.navNameList[_this.$route.query.navName]
    }else{
      _this.active = 0
    }
    // _this.pageCont = _this.page[_this.active]
    // _this.hdTitle = _this.slideList[_this.active].title
    // _this.hdName = _this.slideList[_this.active].title
    // _this.getList(_this.slideList[_this.active].id)
  },
  methods: {
    currentChange(e){
      console.log('e :>> ', e);
    },
    // 查寻二级菜单
    async getSubNav() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: _this.navId});
      _this.slideList = data.data
      if (data.data.length) {
        _this.hdTitle = _this.slideList[_this.active].name
        _this.hdName = _this.slideList[_this.active].name
        _this.getList(_this.slideList[_this.active].id)
      }
    },
    // 查列表
    async getList(id) {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1, pageSize: 10});
      _this.getDetail(data.data.list[0].id)
    },
    // 查详情
    async getDetail(id) {
      var _this = this;

      var { data } = await _this.$http.post( _this.apiUrl + "/news/getBackstageNewsDetail", { id: id });
      _this.pageCont = data.data.content;
    },
  }
}
</script>
<style scoped>
.day {
  display: inline-block;
}
</style>